<template>
  <div>
    <register-confirmation class="mb-3 mt-5 pt-3"  />
  </div>
</template>

<script>
import RegisterConfirmation from '@/components/cleanui/system/Auth/RegisterConfirmation'

export default {
  components: {
    RegisterConfirmation,
  },
}
</script>
